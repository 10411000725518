<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">Edit Car Park</div>
      </v-card-title>
      <v-card-text>
        <template>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-select
                v-model="carPark.siteId"
                :items="sites"
                item-text="name"
                item-value="id"
                label="Select Site"
            ></v-select>
            <v-text-field label="CarPark Name" v-model="carPark.name" required></v-text-field>
            <v-text-field label="Capacity" type="number" v-model="carPark.capacity" required></v-text-field>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()">Cancel</v-btn>
        <v-btn @click="submit()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import toastr from "toastr";

export default {
  name: "carpark-edit",
  data: () => ({
    id: null,
    carParkObject: {},
    carPark: {
      name: '',
      capacity: '',
      siteId: ''
    },
    sites: [],
    valid: true,
  }),
  methods: {
    cancel() {
      this.$router.back()
    },

    submit() {
      this.$store.dispatch('car-parks/update', [this.id, {
        name: this.carPark.name,
        capacity: this.carPark.capacity,
        siteId: this.carPark.siteId //TODO this doesnt persist
      }]).then((result) => {
        toastr.success('Successfully added a car park.', "Success")
        this.$router.back()
      }).catch((error) => {
        console.log(error);
        toastr.error("Failed to edit the car park", "Error");
      })
    },

    /**
     * Populate the site list for the dropdown
     */
    getSiteList() {
      this.$store.dispatch('sites/find').then((result) => {
            this.sites = result.data;
          }
      );
    },

    getCarPark(id) {
      this.$store.dispatch('car-parks/get', id).then((result) => {
        console.log(result);
        this.carPark = result;
      });
    }
  },
  mounted() {
    this.id = this.$route.params.id
    if(this.id == null) {
      toastr.error("404 Not Found", "Not Found")
      this.$router.back()
    }

    this.getSiteList();
    this.getCarPark(this.id);
  }
}
</script>

<style scoped>

</style>